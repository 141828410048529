import React from "react";
import { FaHome, FaUsers } from "react-icons/fa";
import { TbCategoryPlus } from "react-icons/tb";
import { BiSolidCategory } from "react-icons/bi";
import { MdProductionQuantityLimits } from "react-icons/md";
import { FaWpforms } from "react-icons/fa6";
import { MdOutlineSupportAgent } from "react-icons/md";
import { RiAdminFill } from "react-icons/ri";

export const menuItems = [
  {
    path: "/",
    icon: <FaHome className="text-xl" />,
    label: "DASHBOARD",
    roles: ["admin", "subadmin"],
  },
  {
    icon: <TbCategoryPlus className="text-xl" />,
    label: "CATEGORY",
    roles: ["admin", "subadmin"],
    subMenu: [
      {
        path: "/master-category",
        icon: <BiSolidCategory className="text-lg" />,
        label: "Master-Category",
      },
      {
        path: "/main-category",
        icon: <BiSolidCategory className="text-lg" />,
        label: "Main-Category",
      },
      {
        path: "/sub-category",
        icon: <BiSolidCategory className="text-lg" />,
        label: "Sub-Category",
      },
    ],
  },
  {
    icon: <MdProductionQuantityLimits className="text-xl" />,
    label: "VENDORS",
    roles: ["admin", "subadmin"],
    subMenu: [
      {
        path: "/vendor",
        icon: <FaWpforms />,
        label: "Vendor List",
      },
    ],
  },
  {
    icon: <FaUsers className="text-xl" />,
    label: "USERS",
    roles: ["admin", "subadmin"],
    subMenu: [
      {
        path: "/users",
        icon: <FaWpforms />,
        label: "User List",
      },
    ],
  },
  {
    icon: <MdOutlineSupportAgent className="text-xl" />,
    label: "INQUIRY",
    roles: ["admin", "subadmin"],
    subMenu: [
      {
        path: "/inquiry",
        icon: <FaWpforms />,
        label: "INQUIRY List",
      },
    ],
  },
  {
    icon: <RiAdminFill className="text-xl" />,
    label: "SUBADMIN",
    roles: ["admin", "subadmin"],
    subMenu: [
      {
        path: "/subadmin",
        icon: <FaWpforms />,
        label: "Sub-Admin List",
      },
    ],
  },
];
