import { createSlice } from "@reduxjs/toolkit";
import {
  getAllMasterCategory,
  getMasterCategory,
  postMasterCategory,
  softDeleteCategories,
  softDeleteMasterCategory,
  undoDeleteMasterCategory,
  updateMasterCategory,
} from "./masterCategoryAPI";

// Slice for managing master category state
const masterCategorySlice = createSlice({
  name: "masterCategory",
  initialState: {
    allMasterCategory: [],
    masterCategories: [],
    loading: false,
    error: null,
    dataLimit: 0,
    currentPage: 0,
    totalPages: 0,
    totalCategories: 0,
  },
  extraReducers: (builder) => {
    // POST MASTER_CATEGORY REDUCERS
    builder.addCase(postMasterCategory.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(postMasterCategory.fulfilled, (state, action) => {
      state.masterCategories.push(action.payload.category);
      state.loading = false;
    });
    builder.addCase(postMasterCategory.rejected, (state, action) => {
      state.error = action.payload;
      state.loading = false;
    });

    // GET MASTER_CATEGORY FILTER WISE DATA
    builder.addCase(getMasterCategory.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getMasterCategory.fulfilled, (state, action) => {
      state.loading = false;
      state.masterCategories = action.payload.data;
      state.dataLimit = action.payload.meta.limit;
      state.currentPage = action.payload.meta.page;
      state.totalPages = action.payload.meta.totalPages;
      state.totalCategories = action.payload.meta.total;
      state.dataFetched = true;
    });
    builder.addCase(getMasterCategory.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    // GET All MASTER_CATEGORY DATA
    builder.addCase(getAllMasterCategory.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getAllMasterCategory.fulfilled, (state, action) => {
      state.loading = false;
      state.allMasterCategory = action.payload.data;
    });
    builder.addCase(getAllMasterCategory.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    // UPDATE MASTER_CATEGORY DATA
    builder.addCase(updateMasterCategory.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(updateMasterCategory.fulfilled, (state, action) => {
      const index = state.masterCategories.findIndex(
        (category) => category._id === action.payload.category._id
      );
      if (index !== -1) {
        state.masterCategories[index] = action.payload.category;
      }
      state.loading = false;
    });
    builder.addCase(updateMasterCategory.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    // SOFT DELETE MASTER_CATEGORY DATA
    builder.addCase(softDeleteMasterCategory.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(softDeleteMasterCategory.fulfilled, (state, action) => {
      const deletedId = action.payload.data._id || [];
      state.masterCategories = state.masterCategories.filter(
        (category) => !deletedId.includes(category._id)
      );
      state.loading = false;
    });
    builder.addCase(softDeleteMasterCategory.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    // UNDO DELETE MASTER_CATEGORY DATA
    builder.addCase(undoDeleteMasterCategory.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(undoDeleteMasterCategory.fulfilled, (state, action) => {
      const undodeletedId = action.payload.data._id || [];
      state.masterCategories = state.masterCategories.filter(
        (category) => !undodeletedId.includes(category._id)
      );
      state.loading = false;
    });
    builder.addCase(undoDeleteMasterCategory.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    // MULTIPLE MASTER_CATEGORY DATA
    builder.addCase(softDeleteCategories.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(softDeleteCategories.fulfilled, (state, action) => {
      const deletedIds = action.payload || [];
      state.masterCategories = state.masterCategories.filter(
        (category) => !deletedIds.includes(category._id)
      );
      state.loading = false;
    });
    builder.addCase(softDeleteCategories.rejected, (state, action) => {
      state.error = action.payload;
      state.loading = false;
    });
  },
});

export default masterCategorySlice.reducer;
