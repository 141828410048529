import { lazy } from "react";

const RoutePaths = [
  {
    path: "/login",
    component: lazy(() => import("../pages/auth/Login")),
    meta: {
      authRoute: false,
      roles: ["admin", "subadmin"],
    },
  },
  {
    path: "/",
    component: lazy(() => import("../pages/Dashboard/Dashboared")),
    meta: {
      authRoute: false,
      roles: ["admin", "subadmin"],
    },
  },
  ////////////////////////////////////////////////////////////////////////////
  {
    path: "/master-category",
    component: lazy(() => import("../pages/MasterCategory/MasterCategory")),
    meta: {
      authRoute: false,
      roles: ["admin", "subadmin"],
    },
  },
  {
    path: "/masterCategory-add",
    component: lazy(() =>
      import("../pages/MasterCategory/Add/MasterCategoryForm")
    ),
    meta: {
      authRoute: false,
      roles: ["admin", "subadmin"],
    },
  },
  ////////////////////////////////////////////////////////////////////////////
  {
    path: "/main-category/:id?",
    component: lazy(() => import("../pages/MainCategory/MainCategory")),
    meta: {
      authRoute: false,
      roles: ["admin", "subadmin"],
    },
  },
  {
    path: "/mainCategory-add",
    component: lazy(() => import("../pages/MainCategory/Add/MainCategoryForm")),
    meta: {
      authRoute: false,
      roles: ["admin", "subadmin"],
    },
  },
  ////////////////////////////////////////////////////////////////////////////
  {
    path: "/sub-category/:id?",
    component: lazy(() => import("../pages/SubCategory/subCategory")),
    meta: {
      authRoute: false,
      roles: ["admin", "subadmin"],
    },
  },
  {
    path: "/subCategory-add",
    component: lazy(() => import("../pages/SubCategory/Add/SubCategoryForm")),
    meta: {
      authRoute: false,
      roles: ["admin", "subadmin"],
    },
  },

  ////////////////////////////////////////////////////////////////////////////

  {
    path: "/vendor",
    component: lazy(() => import("../pages/Vendor/Vendor")),
    meta: {
      authRoute: false,
      roles: ["admin", "subadmin"],
    },
  },
  {
    path: "/vendor-add",
    component: lazy(() => import("../pages/Vendor/Add/VendorForm")),
    meta: {
      authRoute: false,
      roles: ["admin", "subadmin"],
    },
  },
  {
    path: "/product-add",
    component: lazy(() => import("../pages/Products/Add/ProductForm")),
    meta: {
      authRoute: false,
      roles: ["admin", "subadmin"],
    },
  },
  {
    path: "/product-filterPage/:id/:type",
    component: lazy(() => import("../pages/Products/FilterProductpage")),
    meta: {
      authRoute: false,
      roles: ["admin", "subadmin"],
    },
  },
  ////////////////////////////////////////////////////////////////////////////
  {
    path: "/user-add",
    component: lazy(() => import("../pages/Users/Add/UserForm")),
    meta: {
      authRoute: false,
      roles: ["admin", "subadmin"],
    },
  },
  {
    path: "/users",
    component: lazy(() => import("../pages/Users/Users")),
    meta: {
      authRoute: false,
      roles: ["admin", "subadmin"],
    },
  },
  ////////////////////////////////////////////////////////////////////////////
  {
    path: "/inquiry",
    component: lazy(() => import("../pages/Inquiry/Inquiry")),
    meta: {
      authRoute: false,
      roles: ["admin", "subadmin"],
    },
  },
  ////////////////////////////////////////////////////////////////////////////
  {
    path: "/subadmin",
    component: lazy(() => import("../pages/SubAdmin/Subadmin")),
    meta: {
      authRoute: false,
      roles: ["admin", "subadmin"],
    },
  },
  {
    path: "/subadmin-add",
    component: lazy(() => import("../pages/SubAdmin/Add/SubadminForm")),
    meta: {
      authRoute: false,
      roles: ["admin", "subadmin"],
    },
  },
  //////////////////////////////////////////////////////////////////////////////
  {
    path: "/chatPage",
    component: lazy(() => import("../pages/NewChatApp/Chatpage")),
    meta: {
      authRoute: false,
      roles: ["admin", "subadmin"],
    },
  },
  {
    path: "/product-view",
    component: lazy(() =>
      import("../pages/NewChatApp/ConversationPage/ProductPreview")
    ),
    meta: {
      authRoute: false,
      roles: ["admin", "subadmin"],
    },
  },
];

export default RoutePaths;
