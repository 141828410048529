import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstanceJson from "../../../api/axiosInstanceJson";

// GET USER DATA FILTER WISE
export const getUser = createAsyncThunk(
  "user/getAll",
  async (
    {
      status = "All",
      search = "",
      page = 1,
      limit = 10,
      startDate,
      endDate,
      paginate,
    },
    thunkAPI
  ) => {
    try {
      const { data } = await axiosInstanceJson.get(`/v1/admin/user/get-user`, {
        params: {
          status,
          search,
          page,
          limit,
          startDate,
          endDate,
          paginate,
        },
      });
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

// GET ALL USER
export const getAllUsers = createAsyncThunk(
  "getAllUser",
  async (_, thunkAPI) => {
    try {
      const { data } = await axiosInstanceJson.get(
        `/v1/admin/user/get-allUser`
      );
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

// GET USER BY ID
export const getUserById = createAsyncThunk(
  "user/getById",
  async (id, thunkAPI) => {
    try {
      const { data } = await axiosInstanceJson.get(
        `/v1/admin/user/get-singleUser/${id}`
      );
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

// UPDATE USER DATA
export const updateUser = createAsyncThunk(
  "user/update",
  async (authData, thunkAPI) => {
    try {
      const { data } = await axiosInstanceJson.put(
        `/v1/admin/user/update-user/${authData._id}`,
        authData
      );
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

// SOFT DELETE USER DATA
export const softDeleteUser = createAsyncThunk(
  "user/delete",
  async (id, thunkAPI) => {
    try {
      const { data } = await axiosInstanceJson.put(
        `/v1/admin/user/soft-delete-user/${id}`
      );
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

// UNDO DELETE USER DATA
export const undoDeleteUser = createAsyncThunk(
  "user/undoDelete",
  async (id, thunkAPI) => {
    try {
      const { data } = await axiosInstanceJson.put(
        `/v1/admin/user/undo-delete-user/${id}`
      );
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

// MULTIPLE DELETE USERS DATA
export const softDeleteUsers = createAsyncThunk(
  "users/softDeleteUsers",
  async (ids, thunkAPI) => {
    try {
      await axiosInstanceJson.put(`/v1/admin/user/delete-selected-data`, {
        ids,
      });
      return ids;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
