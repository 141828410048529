import { createSlice } from "@reduxjs/toolkit";
import { getInquiry, updateInquiry } from "./InquiryApi";

// Slice for managing master category state
const inquirySlice = createSlice({
  name: "inquiry",
  initialState: {
    allInquiry: [],
    inquirys: [],
    loading: false,
    error: null,
    dataLimit: 0,
    currentPage: 0,
    totalPages: 0,
    totalCategories: 0,
  },
  extraReducers: (builder) => {
    // GET VENDOR FILTER WISE DATA
    builder.addCase(getInquiry.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getInquiry.fulfilled, (state, action) => {
      state.loading = false;
      state.inquirys = action.payload.data;
      state.dataLimit = action.payload.meta.limit;
      state.currentPage = action.payload.meta.page;
      state.totalPages = action.payload.meta.totalPages;
      state.totalCategories = action.payload.meta.total;
      state.dataFetched = true;
    });
    builder.addCase(getInquiry.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
    // UPDATE INQUIRY STATUS
    builder.addCase(updateInquiry.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(updateInquiry.fulfilled, (state, action) => {
      state.loading = false;
      const updatedInquiry = action.payload.inquiry._id;

      state.inquirys = state.inquirys.filter(
        (inquiry) => !updatedInquiry.includes(inquiry._id)
      );
    });

    builder.addCase(updateInquiry.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
  },
});

export default inquirySlice.reducer;
