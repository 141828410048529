import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "./api";
import axiosInstance from "../../../api/axiosInstanceJson";

// AUTH LOGIN
export const loginUser = createAsyncThunk(
  "auth/login",
  async (authData, thunkAPI) => {
    try {
      const { data } = await axiosInstance.post(
        `/v1/admin/auth/loginAuth`,
        authData
      );

      localStorage.setItem(
        "user",
        JSON.stringify({
          id: data.data._id,
          authName: data.data.fullName,
          authEmail: data.data.email,
          authStatus: data.data.status,
          role: data.data.role,
          token: data.currentLoginToken,
        })
      );
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

// AUTH LOGOUT
export const logoutUser = createAsyncThunk(
  "auth/logout",
  async (_, thunkAPI) => {
    try {
      const storedUser = JSON.parse(localStorage.getItem("user"));
      const token = storedUser?.token;

      if (!token) {
        throw new Error("Token not found, cannot log out.");
      }

      await api.post("auth/logoutAuth", { token });
      localStorage.removeItem("user");
      return { message: "Logged Out Successfully" };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response?.data || error.message);
    }
  }
);
