// src/api.js
import axios from "axios";
import { useNavigate } from "react-router-dom";

const API = process.env.REACT_APP_BASE_URL_LOCAL;

// Create an Axios instance
const api = axios.create({
  baseURL: `${API}/v1/admin/`,
});

// Interceptor to handle token expiration
api.interceptors.response.use(
  (response) => response,
  (error) => {
    const { status } = error.response;
    const navigate = useNavigate();

    if (status === 401) {
      localStorage.removeItem("user");
      navigate("/login");
    }

    return Promise.reject(error);
  }
);

export default api;
