import { createSlice } from "@reduxjs/toolkit";
import {
  getAllMainCategory,
  getFilrerWiseCategory,
  getMainCategory,
  postMainCategory,
  showMainCategory,
  softDeleteCategories,
  softDeleteMainCategory,
  undoDeleteMainCategory,
  updateMainCategory,
} from "./mainCategoryApi";

// Slice for managing master category state
const masterCategorySlice = createSlice({
  name: "mainCategory",
  initialState: {
    filterMainCategory: [],
    allMainCategory: [],
    mainCategories: [],
    activeMainCategory: [],
    loading: false,
    error: null,
    dataLimit: 0,
    currentPage: 0,
    totalPages: 0,
    totalCategories: 0,
  },
  extraReducers: (builder) => {
    // POST MAIN_CATEGORY REDUCERS
    builder.addCase(postMainCategory.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(postMainCategory.fulfilled, (state, action) => {
      state.mainCategories.push(action.payload.category);
      state.loading = false;
    });
    builder.addCase(postMainCategory.rejected, (state, action) => {
      state.error = action.payload;
      state.loading = false;
    });

    // GET MAIN_CATEGORY FILTER WISE DATA
    builder.addCase(getMainCategory.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getMainCategory.fulfilled, (state, action) => {
      state.loading = false;
      state.mainCategories = action.payload.data;
      state.dataLimit = action.payload.meta.limit;
      state.currentPage = action.payload.meta.page;
      state.totalPages = action.payload.meta.totalPages;
      state.totalCategories = action.payload.meta.total;
      state.dataFetched = true;
    });
    builder.addCase(getMainCategory.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    // RELETED SUB-CATEGORY DATA GET
    builder.addCase(getFilrerWiseCategory.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getFilrerWiseCategory.fulfilled, (state, action) => {
      state.loading = false;
      state.filterMainCategory = action.payload.data;
    });
    builder.addCase(getFilrerWiseCategory.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    // GET All MAIN_CATEGORY DATA
    builder.addCase(getAllMainCategory.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getAllMainCategory.fulfilled, (state, action) => {
      state.loading = false;
      state.allMainCategory = action.payload.categories;
    });
    builder.addCase(getAllMainCategory.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    // UPDATE MAIN_CATEGORY DATA
    builder.addCase(updateMainCategory.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(updateMainCategory.fulfilled, (state, action) => {
      const index = state.mainCategories.findIndex(
        (category) => category._id === action.payload.category._id
      );
      if (index !== -1) {
        state.mainCategories[index] = action.payload.category;
      }
      state.loading = false;
    });
    builder.addCase(updateMainCategory.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    // SOFT DELETE MAIN_CATEGORY DATA
    builder.addCase(softDeleteMainCategory.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(softDeleteMainCategory.fulfilled, (state, action) => {
      const deletedId = action.payload.data._id || [];
      state.mainCategories = state.mainCategories.filter(
        (category) => !deletedId.includes(category._id)
      );
      state.loading = false;
    });
    builder.addCase(softDeleteMainCategory.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    // UNDO DELETE MASTER_CATEGORY DATA
    builder.addCase(undoDeleteMainCategory.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(undoDeleteMainCategory.fulfilled, (state, action) => {
      const undodeletedId = action.payload.data._id || [];
      state.mainCategories = state.mainCategories.filter(
        (category) => !undodeletedId.includes(category._id)
      );
      state.loading = false;
    });
    builder.addCase(undoDeleteMainCategory.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    // MULTIPLE MASTER_CATEGORY DATA
    builder.addCase(softDeleteCategories.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(softDeleteCategories.fulfilled, (state, action) => {
      const deletedIds = action.payload || [];
      state.mainCategories = state.mainCategories.filter(
        (category) => !deletedIds.includes(category._id)
      );
      state.loading = false;
    });
    builder.addCase(softDeleteCategories.rejected, (state, action) => {
      state.error = action.payload;
      state.loading = false;
    });

    // MASTER_CATEGORY REALATED MAIN_CATEGORY SHOW
    builder.addCase(showMainCategory.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(showMainCategory.fulfilled, (state, action) => {
      state.loading = false;
      state.activeMainCategory = action.payload;
    });
    builder.addCase(showMainCategory.rejected, (state, action) => {
      state.error = action.payload;
      state.loading = false;
    });
  },
});

export default masterCategorySlice.reducer;
