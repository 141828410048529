import { createSlice } from "@reduxjs/toolkit";
import {
  getAllSubCategory,
  getSubCategory,
  postSubCategory,
  softDeleteCategories,
  softDeleteSubCategory,
  undoDeleteSubCategory,
  updateSubCategory,
} from "./subCategoryApi.js";

// Slice for managing master category state
const masterCategorySlice = createSlice({
  name: "subCategory",
  initialState: {
    allSubCategory: [],
    subCategories: [],
    loading: false,
    error: null,
    dataLimit: 0,
    currentPage: 0,
    totalPages: 0,
    totalCategories: 0,
  },
  extraReducers: (builder) => {
    // POST MAIN_CATEGORY REDUCERS
    builder.addCase(postSubCategory.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(postSubCategory.fulfilled, (state, action) => {
      state.subCategories.push(action.payload.category);
      state.loading = false;
    });
    builder.addCase(postSubCategory.rejected, (state, action) => {
      state.error = action.payload;
      state.loading = false;
    });

    // GET MAIN_CATEGORY FILTER WISE DATA
    builder.addCase(getSubCategory.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getSubCategory.fulfilled, (state, action) => {
      state.loading = false;
      state.subCategories = action.payload.data;
      state.dataLimit = action.payload.meta.limit;
      state.currentPage = action.payload.meta.page;
      state.totalPages = action.payload.meta.totalPages;
      state.totalCategories = action.payload.meta.total;
      state.dataFetched = true;
    });
    builder.addCase(getSubCategory.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    // GET All MAIN_CATEGORY DATA
    builder.addCase(getAllSubCategory.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getAllSubCategory.fulfilled, (state, action) => {
      state.loading = false;
      state.allSubCategory = action.payload.categories;
    });
    builder.addCase(getAllSubCategory.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    // UPDATE MAIN_CATEGORY DATA
    builder.addCase(updateSubCategory.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(updateSubCategory.fulfilled, (state, action) => {
      const index = state.subCategories.findIndex(
        (category) => category._id === action.payload.category._id
      );
      if (index !== -1) {
        state.subCategories[index] = action.payload.category;
      }
      state.loading = false;
    });
    builder.addCase(updateSubCategory.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    // SOFT DELETE MAIN_CATEGORY DATA
    builder.addCase(softDeleteSubCategory.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(softDeleteSubCategory.fulfilled, (state, action) => {
      const deletedId = action.payload.data._id || [];
      state.subCategories = state.subCategories.filter(
        (category) => !deletedId.includes(category._id)
      );
      state.loading = false;
    });
    builder.addCase(softDeleteSubCategory.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    // UNDO DELETE MASTER_CATEGORY DATA
    builder.addCase(undoDeleteSubCategory.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(undoDeleteSubCategory.fulfilled, (state, action) => {
      const undodeletedId = action.payload.data._id || [];
      state.subCategories = state.subCategories.filter(
        (category) => !undodeletedId.includes(category._id)
      );
      state.loading = false;
    });
    builder.addCase(undoDeleteSubCategory.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    // MULTIPLE MASTER_CATEGORY DATA
    builder.addCase(softDeleteCategories.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(softDeleteCategories.fulfilled, (state, action) => {
      const deletedIds = action.payload || [];
      state.subCategories = state.subCategories.filter(
        (category) => !deletedIds.includes(category._id)
      );
      state.loading = false;
    });
    builder.addCase(softDeleteCategories.rejected, (state, action) => {
      state.error = action.payload;
      state.loading = false;
    });
  },
});

export default masterCategorySlice.reducer;
