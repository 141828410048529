import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../../api/axiosInstance";
import axiosInstancejson from "../../../api/axiosInstanceJson";

// POST MASTER_CATEGORY DATA
export const postMasterCategory = createAsyncThunk(
  "masterCategory/post",
  async (categorydata, thunkAPI) => {
    try {
      const { data } = await axiosInstance.post(
        `/v1/admin/mastercategory/post-mastercategory`,
        categorydata
      );
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

// GET MASTER_CATEGORY DATA FILTER WISE
export const getMasterCategory = createAsyncThunk(
  "masterCategory/getAll",
  async (
    {
      id,
      status = "All",
      search = "",
      page = 1,
      limit = 10,
      startDate,
      endDate,
      paginate,
    },
    thunkAPI
  ) => {
    try {
      const { data } = await axiosInstance.get(
        `/v1/admin/mastercategory/get-mastercategory`,
        {
          params: {
            id,
            status,
            search,
            page,
            limit,
            startDate,
            paginate,
            endDate,
          },
        }
      );
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

// GET ALL MASTER_CATEGORY
export const getAllMasterCategory = createAsyncThunk(
  "getAllCategory",
  async (_, thunkAPI) => {
    try {
      const { data } = await axiosInstancejson.get(
        `/v1/admin/mastercategory/get-allMasterCategory`
      );
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

// GET MASTER_CATEGORY BY ID
export const getMasterCategoryById = createAsyncThunk(
  "masterCategory/getById",
  async (id, thunkAPI) => {
    try {
      const { data } = await axiosInstance.get(
        `/v1/admin/mastercategory/get-singleMasterCategory/${id}`
      );
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

// UPDATE MASTER_CATEGORY DATA
export const updateMasterCategory = createAsyncThunk(
  "masterCategory/update",
  async (authData, thunkAPI) => {
    try {
      const { data } = await axiosInstance.put(
        `/v1/admin/mastercategory/update-mastercategory/${authData._id}`,
        authData
      );
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

// SOFT DELETE MASTER_CATEGORY DATA
export const softDeleteMasterCategory = createAsyncThunk(
  "masterCategory/delete",
  async (id, thunkAPI) => {
    try {
      const { data } = await axiosInstance.put(
        `/v1/admin/mastercategory/soft-delete-mastercategory/${id}`
      );
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

// UNDO DELETE MASTER_CATEGORY DATA
export const undoDeleteMasterCategory = createAsyncThunk(
  "masterCategory/undoDelete",
  async (id, thunkAPI) => {
    try {
      const { data } = await axiosInstance.put(
        `/v1/admin/mastercategory/undo-delete-mastercategory/${id}`
      );
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

// MULTIPLE DELETE MASTER_CATEGORY DATA
export const softDeleteCategories = createAsyncThunk(
  "categories/softDeleteCategories",
  async (ids, thunkAPI) => {
    try {
      await axiosInstancejson.put(
        `/v1/admin/mastercategory/delete-selected-data`,
        {
          ids,
        }
      );
      return ids;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
