import { createSlice } from "@reduxjs/toolkit";
import {
  // getAllProduct,
  getProduct,
  postProduct,
  softDeleteProduct,
  softDeleteProducts,
  undoDeleteProduct,
  updateProduct,
} from "./productAPI";

const productSlice = createSlice({
  name: "product",
  initialState: {
    allProducts: [],
    products: [],
    loading: false,
    error: null,
    dataLimit: 10,
    currentPage: 1,
    totalPages: 0,
    totalProducts: 0,
  },
  reducers: {
    // You can add other reducers here if needed
  },
  extraReducers: (builder) => {
    // GET PRODUCT FILTER WISE DATA
    builder.addCase(getProduct.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getProduct.fulfilled, (state, action) => {
      state.loading = false;
      state.products = action.payload.data;
      state.dataLimit = action.payload.meta.limit;
      state.currentPage = action.payload.meta.page;
      state.totalPages = action.payload.meta.totalPages;
      state.totalCategories = action.payload.meta.total;
      state.dataFetched = true;
    });
    builder.addCase(getProduct.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    // GET All PRODUCT DATA
    // builder.addCase(getAllProduct.pending, (state) => {
    //   state.loading = true;
    //   state.error = null;
    // });
    // builder.addCase(getAllProduct.fulfilled, (state, action) => {
    //   state.loading = false;
    //   state.allProducts = action.payload.data;
    // });
    // builder.addCase(getAllProduct.rejected, (state, action) => {
    //   state.loading = false;
    //   state.error = action.payload;
    // });

    // UPDATE PRODUCT DATA
    builder.addCase(updateProduct.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(updateProduct.fulfilled, (state, action) => {
      const index = state.products.findIndex(
        (product) => product._id === action.payload._id
      );
      if (index !== -1) {
        state.products[index] = action.payload;
      }
      state.loading = false;
    });
    builder.addCase(updateProduct.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    // SOFT DELETE PRODUCT DATA
    builder.addCase(softDeleteProduct.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(softDeleteProduct.fulfilled, (state, action) => {
      const deletedId = action.payload.data._id || [];
      state.products = state.products.filter(
        (product) => !deletedId.includes(product._id)
      );
      state.loading = false;
    });
    builder.addCase(softDeleteProduct.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    // UNDO DELETE PRODUCT DATA
    builder.addCase(undoDeleteProduct.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(undoDeleteProduct.fulfilled, (state, action) => {
      const undodeletedId = action.payload.data._id || [];
      state.products = state.products.filter(
        (product) => !undodeletedId.includes(product._id)
      );
      state.loading = false;
    });
    builder.addCase(undoDeleteProduct.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    // MULTIPLE MASTER_CATEGORY DATA
    builder.addCase(softDeleteProducts.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(softDeleteProducts.fulfilled, (state, action) => {
      const deletedIds = action.payload || [];
      state.products = state.products.filter(
        (product) => !deletedIds.includes(product._id)
      );
      state.loading = false;
    });
    builder.addCase(softDeleteProducts.rejected, (state, action) => {
      state.error = action.payload;
      state.loading = false;
    });
  },
});

export default productSlice.reducer;
